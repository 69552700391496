import React from 'react';
import cn from 'classnames';

export type ContainerProps = Pick<JSX.IntrinsicElements['div'], 'children' | 'className'>;

// max-w-7xl mx-auto sm:px-6 lg:px-8
const classes = {
  container: `odin-mx-auto odin-pt-6 odin-px-4 odin-pb-6 
  odin-flex odin-flex-col odin-gap-y-4
  sm:odin-gap-y-7.5 sm:odin-px-6 md:odin-px-8`,
};

export function Container(props: ContainerProps): React.ReactElement {
  const { children, className } = props;
  return <div className={cn(className, classes.container)}>{children}</div>;
}
