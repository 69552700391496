import cn from 'classnames';
import { SelectOptionElement } from '@odin-labs/components';
import { FormInputTypes, GridColSpan, TypedFormInputs } from 'components/form';
import { BriefcaseSolidIcon, BuildingColumnsIcon, MapMarkerAltIcon } from 'components/icons';
import { zipCodeValidation } from 'utils/validation';
import { statesOptions, timeZonesOptions } from 'utils/constants';
import { fillAddressDetails } from 'components/placesAutocomplete/utils';
import { EditJobsiteFormData } from './types';

export type GetFormInputsArgs = {
  templatesOptions: SelectOptionElement[];
  developersOptions: SelectOptionElement[];
  isDeveloperDisabled: boolean;
  isTemplateRequired: boolean;
};

export const getFormInputs = ({
  templatesOptions,
  developersOptions,
  isDeveloperDisabled,
  isTemplateRequired,
}: GetFormInputsArgs): TypedFormInputs<EditJobsiteFormData> => ({
  name: {
    element: FormInputTypes.Field,
    label: 'Name',
    validation: { required: true },
    layout: GridColSpan.SpanFull,
  },
  templateId: {
    element: FormInputTypes.Select,
    label: 'Template',
    elementProps: {
      options: templatesOptions,
      icon: BriefcaseSolidIcon,
    },
    validation: { required: isTemplateRequired },
    layout: cn('sm:odin-col-start-1', GridColSpan.SpanFull, GridColSpan.SmSpan2),
  },
  developerId: {
    element: FormInputTypes.Select,
    label: 'Client',
    elementProps: {
      disabled: isDeveloperDisabled,
      options: developersOptions,
      icon: BuildingColumnsIcon,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressLine1: {
    element: FormInputTypes.PlacesAutocomplete,
    label: 'Address',
    elementProps: {
      placeholder: 'Address',
      icon: MapMarkerAltIcon,
      onCommit: fillAddressDetails,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressLine2: {
    element: FormInputTypes.Field,
    label: 'Address, Line 2',
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressCity: {
    element: FormInputTypes.Field,
    label: 'City',
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  addressState: {
    element: FormInputTypes.Select,
    label: 'State',
    elementProps: {
      options: statesOptions,
    },
    validation: { required: true },
    layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
  },
  addressZipCode: {
    element: FormInputTypes.Field,
    label: 'ZIP Code',
    layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
    elementProps: {
      fieldType: 'zipcode',
    },
    validation: {
      required: true,
      pattern: zipCodeValidation,
    },
  },
  timeZone: {
    element: FormInputTypes.Select,
    label: 'Timezone',
    elementProps: {
      options: timeZonesOptions,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  latitude: {
    element: FormInputTypes.Field,
    label: 'Latitude',
    elementProps: { fieldType: 'gpsCoordinate' },
    validation: { required: true },
    layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
  },
  longitude: {
    element: FormInputTypes.Field,
    label: 'Longitude',
    elementProps: { fieldType: 'gpsCoordinate' },
    validation: { required: true },
    layout: [GridColSpan.Span2, GridColSpan.SmSpan1],
  },
  startDate: {
    element: FormInputTypes.DatePicker,
    label: 'Start Date',
    elementProps: {
      showDefaultIcon: true,
      numberOfMonths: 1,
    },
    validation: { required: true },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
  endDate: {
    element: FormInputTypes.DatePicker,
    label: 'End Date',
    elementProps: {
      showDefaultIcon: true,
      numberOfMonths: 1,
    },
    layout: [GridColSpan.SpanFull, GridColSpan.SmSpan2],
  },
});
