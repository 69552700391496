import React, { useState, ReactElement } from 'react';
import {
  GetJobsitesContainerCurrentSessionDocument,
  useGetDevelopersQuery,
  useGetJobsiteTemplatesQuery,
  useJobsiteCreateMutation,
} from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { PlusIcon } from 'components/icons';
import { FormOnSubmit, ModalForm } from 'components/form';
import { AlertService } from 'components/alertService';
import { useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getDevelopersOptions, getJobsiteTemplateOptions } from 'containers/jobsites/helpers/utils';
import { useAvailableJobsites } from 'graphql/client/useAvailableJobsites';
import { getAddInput, getDefaultValues, getFormInputsHook } from './AddJobsiteModal.forms';
import { EditJobsiteFormData, AddJobsiteModalProps } from './types';

export function AddJobsiteModal(props: AddJobsiteModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm } = props;

  const isMounted = useIsMounted();
  const [isFetching, setFetching] = useState<boolean>(false);
  const { currentUser: user } = React.useContext(AuthContext);

  const { data: developersData } = useGetDevelopersQuery({ fetchPolicy: 'no-cache', skip: !isOpen });
  const { data: templatesData } = useGetJobsiteTemplatesQuery({ fetchPolicy: 'no-cache', skip: !isOpen });

  const [jobsiteCreate] = useJobsiteCreateMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetJobsitesContainerCurrentSessionDocument],
  });

  const onSubmit: FormOnSubmit<EditJobsiteFormData> = async (data, event, dirtyFields): Promise<void> => {
    if (isFetching) return;
    setFetching(true);

    try {
      const input = getAddInput(data, dirtyFields);
      const result = await jobsiteCreate({ variables: { input } });

      useAvailableJobsites.resetCacheOnNextCall();

      onConfirm(result?.data?.jobsiteCreate.jobsite);
      AlertService.alert('success', 'Success', 'Jobsite successfully created');
    } catch (error) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setFetching(false);
      }
    }
  };

  const developersOptions = React.useMemo(() => {
    const developers = developersData?.getCurrentSession.user.developers.edges.map(({ node }) => node);
    return getDevelopersOptions(developers);
  }, [developersData]);

  const templatesOptions = React.useMemo(() => {
    const templates = templatesData?.getCurrentSession.user.jobsites.edges.map(({ node }) => node);
    return getJobsiteTemplateOptions(templates);
  }, [templatesData]);

  const inputs = getFormInputsHook({ user, templatesOptions, developersOptions });
  const defaultValues = React.useMemo(
    () => getDefaultValues({ templatesOptions, developersOptions }),
    [templatesOptions, developersOptions],
  );

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title="Add Jobsite"
      inputs={inputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-4 odin-gap-6"
      actionText="Add Jobsite"
      actionIcon={PlusIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
