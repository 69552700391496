import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import { SelectOptionElement, useDidUpdateEffect } from '@odin-labs/components';
import { InputFile } from 'types';
import { ensureNonEmptyItems, useUpdatableState } from 'utils';
import {
  toFancySelectOptions,
  statesOptions,
  sstCardTypes as sstCardOptionsValues,
  govCardTypes as govCardOptionsValues,
  oshaCardTypes as oshaCardOptionsValues,
  otherCardTypes as otherCardOptionsValues,
} from 'utils/constants';
import { FormInput, UseInputs, UseFormMethods, FormDefaultValue, FormInputTypes, GridColSpan } from 'components/form';
import {
  accept,
  DocumentWithConditionalRequiredInputs,
  DocumentWithExistingDocumentAlert,
  DocumentWithPreviewUrl,
  DocumentWithRequiredInputs,
  generateJobsiteWorkerDocument,
  getDocumentPreviewUrl,
  getDocumentsWithExistingDocumentAlerts,
  withConditionalRequired,
} from 'containers/workerOnboarding/helpers/forms';
import {
  JobsiteWorker,
  WorkerDocumentsDataType,
  OnboardingStepProps,
  OnboardingStepKey,
  JwDocument,
} from 'containers/workerOnboarding/types';
import { DocumentKey } from 'containers/worker/utils';
import { FilePlusIcon } from 'components/icons';
import { getDocumentsConfig, getVisibleDocuments } from 'containers/workerOnboarding/helpers/utils';
import { toggleBorderClasses } from 'components/utils';
import { dateValidation } from 'utils/validation';
import { dropzoneValidation } from 'components/dropzone/utils';
import { classes } from './WorkDocumentsStep.style';

const govCardOptions = toFancySelectOptions(govCardOptionsValues);
const sstCardOptions = toFancySelectOptions(sstCardOptionsValues);
const oshaCardOptions = toFancySelectOptions(oshaCardOptionsValues);
const otherCardOptions = toFancySelectOptions(otherCardOptionsValues);

export const workerDocumentsKeys = [
  DocumentKey.NycSiteSafetyTrainingCard,
  DocumentKey.OshaCard,
  DocumentKey.GovernmentIssuedId,
  DocumentKey.LIRRBlueTraining,
  DocumentKey.ConfinedSpacesTraining,
  // DocumentKey.AdditionalCertifications,
] as const;

type WorkerDocumentKey = typeof workerDocumentsKeys[number];

// type UniqueDocumentKeys =
//   | DocumentKey.NycSiteSafetyTrainingCard
//   | DocumentKey.OshaCard
//   | DocumentKey.GovernmentIssuedId
//   | DocumentKey.LIRRBlueTraining
//   | DocumentKey.ConfinedSpacesTraining;

// type NonUniqueDocumentKeys = DocumentKey.AdditionalCertifications | DocumentKey.Generic;

export const stringFields = ['exemption-reason', 'number', 'issue-date', 'expiration-date', 'document'] as const;
export const booleanFields = ['is-training-connect-card', 'exempt'] as const;
export const selectOptionsFields = ['type', 'card-type', 'state-issued'] as const;
export const fileFields = ['front', 'back'] as const;

export type StringField = typeof stringFields[number];
export type BooleanField = typeof booleanFields[number];
export type SelectOptionField = typeof selectOptionsFields[number];
export type FileField = typeof fileFields[number];

type WorkerDocumentFields<TDocumentKeys extends DocumentKey, TFieldName extends string, TFieldValueType> = {
  [Property in TDocumentKeys as `${Property}-${TFieldName}`]: TFieldValueType;
};

export type WorkerDocumentsFormData<TWorkerDocumentKey extends DocumentKey = WorkerDocumentKey> = WorkerDocumentFields<
  TWorkerDocumentKey,
  StringField,
  string
> &
  WorkerDocumentFields<TWorkerDocumentKey, BooleanField, boolean> &
  WorkerDocumentFields<TWorkerDocumentKey, SelectOptionField, SelectOptionElement> &
  WorkerDocumentFields<TWorkerDocumentKey, FileField, InputFile>;

export type WorkerDocumentFieldKey = StringField | BooleanField | SelectOptionField | FileField;

export function getDocumentDataValue(data: WorkerDocumentsFormData, documentKey: string, field: StringField): string;
export function getDocumentDataValue(data: WorkerDocumentsFormData, documentKey: string, field: BooleanField): boolean;
export function getDocumentDataValue(
  data: WorkerDocumentsFormData,
  documentKey: string,
  field: SelectOptionField,
): SelectOptionElement;
export function getDocumentDataValue(data: WorkerDocumentsFormData, documentKey: string, field: FileField): InputFile;
export function getDocumentDataValue(
  data: WorkerDocumentsFormData,
  documentKey: string,
  field: WorkerDocumentFieldKey,
): string | boolean | SelectOptionElement | InputFile {
  return data[`${documentKey}-${field}` as keyof WorkerDocumentsFormData];
}

export function getDataValueFunction(
  data: WorkerDocumentsFormData,
  documentKey: string,
): {
  (field: StringField): string;
  (field: BooleanField): boolean;
  (field: SelectOptionField): SelectOptionElement;
  (field: FileField): InputFile;
} {
  function getDataValue(field: StringField): string;
  function getDataValue(field: BooleanField): boolean;
  function getDataValue(field: SelectOptionField): SelectOptionElement;
  function getDataValue(field: FileField): InputFile;
  // Implementation signature, not publicly visible
  function getDataValue(field: WorkerDocumentFieldKey): string | boolean | SelectOptionElement | InputFile {
    return data[`${documentKey}-${field}` as keyof WorkerDocumentsFormData];
  }

  return getDataValue;
}

type DocumentInputArgs = DocumentWithPreviewUrl &
  DocumentWithRequiredInputs &
  DocumentWithConditionalRequiredInputs<WorkerDocumentsFormData> &
  DocumentWithExistingDocumentAlert & {
    hideLabel?: boolean;
  };

export type NewYorkSstDocumentInputArgs = DocumentInputArgs & {
  isSSTExemptVisible?: boolean;
  sstExempt?: boolean;
};

export const newYorkSstDocumentInput = (args: NewYorkSstDocumentInputArgs): FormInput<WorkerDocumentsFormData> => {
  const {
    sstExempt,
    areInputsRequired = false,
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
    isSSTExemptVisible,
    previewUrl,
    getPreviewUrl,
    showExistingDocumentAlert,
    getShowExistingDocumentAlert,
    hideLabel,
  } = args;

  const documentKey = DocumentKey.NycSiteSafetyTrainingCard;

  return withConditionalRequired({
    document: {
      name: `${documentKey}-document`,
      element: FormInputTypes.JobsiteWorkerDocument,
      label: 'NYC Site Safety Training Card',
      elementProps: {
        hideLabel,
        previewUrl: previewUrl ?? getPreviewUrl?.(documentKey),
        showExistingDocumentAlert: showExistingDocumentAlert ?? getShowExistingDocumentAlert?.(documentKey),
      },
      layout: '',
      children: ensureNonEmptyItems<FormInput<WorkerDocumentsFormData>>([
        isSSTExemptVisible && {
          name: `${documentKey}-exempt`,
          element: FormInputTypes.Toggle,
          label: 'Exempt from SST',
          elementProps: {
            // toggleDescription: 'Please indicate if this user is exempt from the SST',
            checked: sstExempt,
            toggleAlignment: 'right-with-space',
          },
          layout: [toggleBorderClasses, GridColSpan.SpanFull, GridColSpan.SmSpan6],
        },
        {
          name: `${documentKey}-is-training-connect-card`,
          element: FormInputTypes.Toggle,
          label: 'Training Connect Card',
          elementProps: {
            toggleAlignment: 'right-with-space',
          },
          layout: [
            toggleBorderClasses,
            GridColSpan.SpanFull,
            isSSTExemptVisible ? GridColSpan.SmSpan6 : GridColSpan.SpanFull,
          ],
        },
        sstExempt && {
          name: `${documentKey}-exemption-reason`,
          element: FormInputTypes.Field,
          label: 'SST Exempt Reason',
          elementProps: {
            placeholder: 'SST Exempt Reason',
          },
          layout: [GridColSpan.SpanFull],
        },
        !sstExempt && {
          name: `${documentKey}-type`,
          element: FormInputTypes.Select,
          label: 'Card type',
          elementProps: {
            placeholder: 'Select one',
            options: sstCardOptions,
          },
          validation: { required: areInputsRequired },
          layout: [GridColSpan.SpanFull],
        },
        !sstExempt && {
          name: `${documentKey}-issue-date`,
          element: FormInputTypes.Field,
          label: 'Issue date',
          elementProps: {
            fieldType: 'date',
            placeholder: 'Issue date',
          },
          validation: {
            pattern: dateValidation,
            required: areInputsRequired,
          },
          layout: cn(GridColSpan.SpanFull, GridColSpan.SmSpan6, 'sm:odin-col-start-1'),
        },
        !sstExempt && {
          name: `${documentKey}-expiration-date`,
          element: FormInputTypes.Field,
          label: 'Expiration date',
          elementProps: {
            fieldType: 'date',
            placeholder: 'Expiration date',
          },
          validation: {
            pattern: dateValidation,
            required: areInputsRequired,
          },
          layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
        },
        !sstExempt && {
          name: `${documentKey}-front`,
          element: FormInputTypes.Dropzone,
          label: 'SST card front upload',
          elementProps: {
            accept,
          },
          validation: {
            required: areInputsRequired,
            validate: dropzoneValidation,
          },
          layout: cn(GridColSpan.Span6, 'odin-col-start-1'),
        },
        !sstExempt && {
          name: `${documentKey}-back`,
          element: FormInputTypes.Dropzone,
          label: 'SST card back upload',
          elementProps: {
            accept,
          },
          layout: [GridColSpan.Span6],
        },
      ]),
    },
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
  });
};

export const oshaWorkerDocumentInput = (args?: DocumentInputArgs): FormInput<WorkerDocumentsFormData> => {
  const {
    areInputsRequired = false,
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
    previewUrl,
    getPreviewUrl,
    showExistingDocumentAlert,
    getShowExistingDocumentAlert,
    hideLabel,
  } = args ?? {};
  const documentKey = DocumentKey.OshaCard;

  return withConditionalRequired({
    document: {
      name: `${documentKey}-document`,
      element: FormInputTypes.JobsiteWorkerDocument,
      label: 'OSHA card',
      elementProps: {
        hideLabel,
        previewUrl: previewUrl ?? getPreviewUrl?.(documentKey),
        showExistingDocumentAlert: showExistingDocumentAlert ?? getShowExistingDocumentAlert?.(documentKey),
      },
      layout: '',
      children: [
        {
          name: `${documentKey}-type`,
          element: FormInputTypes.Select,
          label: 'Card type',
          elementProps: {
            placeholder: 'Select one',
            options: oshaCardOptions,
          },
          validation: { required: areInputsRequired },
          layout: [GridColSpan.SpanFull],
        },
        {
          name: `${documentKey}-issue-date`,
          element: FormInputTypes.Field,
          label: 'Course date',
          elementProps: {
            fieldType: 'date',
            placeholder: 'Course date',
          },
          validation: {
            pattern: dateValidation,
            required: areInputsRequired,
          },
          layout: cn(GridColSpan.SpanFull, GridColSpan.SmSpan6, 'sm:odin-col-start-1'),
        },
        {
          name: `${documentKey}-number`,
          element: FormInputTypes.Field,
          label: 'ID number',
          elementProps: {
            placeholder: 'ID number',
          },
          validation: {
            required: areInputsRequired,
          },
          layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
        },
        {
          name: `${documentKey}-front`,
          element: FormInputTypes.Dropzone,
          label: 'OSHA card upload front',
          elementProps: {
            accept,
          },
          validation: {
            required: areInputsRequired,
            validate: dropzoneValidation,
          },
          layout: GridColSpan.SpanFull,
        },
      ],
    },
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
  });
};

export const governmentIdDocumentInput = (args?: DocumentInputArgs): FormInput<WorkerDocumentsFormData> => {
  const {
    areInputsRequired,
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
    previewUrl,
    getPreviewUrl,
    showExistingDocumentAlert,
    getShowExistingDocumentAlert,
    hideLabel,
  } = args ?? {};
  const documentKey = DocumentKey.GovernmentIssuedId;

  return withConditionalRequired({
    document: {
      name: `${documentKey}-document`,
      element: FormInputTypes.JobsiteWorkerDocument,
      label: 'Government ID',
      elementProps: {
        hideLabel,
        previewUrl: previewUrl ?? getPreviewUrl?.(documentKey),
        showExistingDocumentAlert: showExistingDocumentAlert ?? getShowExistingDocumentAlert?.(documentKey),
      },
      layout: '',
      children: [
        {
          name: `${documentKey}-type`,
          element: FormInputTypes.Select,
          label: 'Card type',
          elementProps: {
            placeholder: 'Select one',
            options: govCardOptions,
          },
          layout: [GridColSpan.SpanFull],
        },
        {
          name: `${documentKey}-state-issued`,
          element: FormInputTypes.Select,
          label: 'State issued',
          elementProps: {
            placeholder: 'Select one',
            options: statesOptions,
          },
          layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
        },
        {
          name: `${documentKey}-number`,
          element: FormInputTypes.Field,
          label: 'ID number',
          elementProps: {
            placeholder: 'ID number',
          },
          validation: { required: areInputsRequired },
          layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
        },
        {
          name: `${documentKey}-issue-date`,
          element: FormInputTypes.Field,
          label: 'Issue date',
          elementProps: {
            fieldType: 'date',
            placeholder: 'Issue date',
          },
          validation: { pattern: dateValidation, required: areInputsRequired },
          layout: cn(GridColSpan.SpanFull, GridColSpan.SmSpan6, 'sm:odin-col-start-1'),
        },
        {
          name: `${documentKey}-expiration-date`,
          element: FormInputTypes.Field,
          label: 'Expiration date',
          elementProps: {
            fieldType: 'date',
            placeholder: 'Expiration date',
          },
          validation: { pattern: dateValidation, required: areInputsRequired },
          layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
        },
        {
          name: `${documentKey}-front`,
          element: FormInputTypes.Dropzone,
          label: 'Government card upload',
          elementProps: {
            accept,
          },
          validation: {
            required: areInputsRequired,
            validate: dropzoneValidation,
          },
          layout: GridColSpan.SpanFull,
        },
      ],
    },
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
  });
};

export const lirrBlueTrainingDocumentInput = (args?: DocumentInputArgs): FormInput<WorkerDocumentsFormData> => {
  const { areInputsRequired, ...restArgs } = args;
  return generateJobsiteWorkerDocument({
    documentKey: DocumentKey.LIRRBlueTraining,
    label: 'LIRR Blue Training',
    hasExpirationDate: true,
    requiredFields: areInputsRequired ? ['front', 'expiration-date'] : undefined,
    ...restArgs,
  });
};

export const confinedSpacesTrainingDocumentInput = (args?: DocumentInputArgs): FormInput<WorkerDocumentsFormData> => {
  const { areInputsRequired, ...restArgs } = args;
  return generateJobsiteWorkerDocument({
    documentKey: DocumentKey.ConfinedSpacesTraining,
    label: 'Confined Spaces Training',
    hasExpirationDate: true,
    requiredFields: areInputsRequired ? ['front', 'expiration-date'] : undefined,
    ...restArgs,
  });
};

export type WorkerDocumentsInputArgs = DocumentWithRequiredInputs &
  DocumentWithConditionalRequiredInputs<WorkerDocumentsFormData> &
  DocumentWithExistingDocumentAlert & {
    jobsiteWorker: JobsiteWorker;
    filteredDocumentTypeIds: Record<string, string>;
    isSSTExemptVisible?: boolean;
    sstExempt: boolean;
    getPreviewUrl: DocumentWithPreviewUrl['getPreviewUrl'];
  };

export const workerDocumentsInput = ({
  jobsiteWorker,
  filteredDocumentTypeIds,
  suppressRequiredWhenDocIsNotDirty,
  dirtyFields,
  isSSTExemptVisible,
  sstExempt,
  getPreviewUrl,
  getShowExistingDocumentAlert,
}: WorkerDocumentsInputArgs): FormInput<WorkerDocumentsFormData>[] => {
  if (!jobsiteWorker) return [];

  const { jobsite } = jobsiteWorker?.jobsiteContractor ?? {};

  const documentsConfig = getDocumentsConfig(jobsite.modules);
  const getDocumentIndex = (key: string): number => documentsConfig[key as DocumentKey]?.index ?? 999;
  const visibleDocuments = getVisibleDocuments(jobsiteWorker);

  const commonArgs: Pick<
    WorkerDocumentsInputArgs,
    | 'getPreviewUrl'
    | 'suppressRequiredWhenDocIsNotDirty'
    | 'dirtyFields'
    | 'getShowExistingDocumentAlert'
    | 'areInputsRequired'
  > = {
    getPreviewUrl,
    getShowExistingDocumentAlert,
    suppressRequiredWhenDocIsNotDirty,
    dirtyFields,
    areInputsRequired: true,
  };

  const workerDocuments: Record<WorkerDocumentKey, () => FormInput<WorkerDocumentsFormData>> = {
    [DocumentKey.NycSiteSafetyTrainingCard]: () =>
      newYorkSstDocumentInput({
        isSSTExemptVisible,
        sstExempt,
        ...commonArgs,
      }),
    [DocumentKey.OshaCard]: () => oshaWorkerDocumentInput(commonArgs),
    [DocumentKey.GovernmentIssuedId]: () => governmentIdDocumentInput(commonArgs),
    [DocumentKey.LIRRBlueTraining]: () => lirrBlueTrainingDocumentInput(commonArgs),
    [DocumentKey.ConfinedSpacesTraining]: () => confinedSpacesTrainingDocumentInput(commonArgs),
    // [DocumentKey.AdditionalCertifications]: () =>
    //   generateJobsiteWorkerDocument({
    //     documentKey: DocumentKey.AdditionalCertifications,
    //     label: 'Additional certifications',
    //     ...commonArgs,
    //   }),
  };

  return Object.entries(workerDocuments)
    .filter(([key]) => filteredDocumentTypeIds[key] && visibleDocuments?.includes(key))
    .sort(([aKey], [bKey]) => getDocumentIndex(aKey) - getDocumentIndex(bKey))
    .flatMap(([, getInputs]) => getInputs());
};

type UseNonUniqueDocumentsArgs = {
  documentKey: DocumentKey;
  documents: JwDocument[];
  addWhenEmpty?: boolean;
  loading: boolean;
};

const getNewDocumentKey = (documentKey: DocumentKey): string =>
  `${documentKey}-new-${moment().format('YYYYMMDDHHmmssSSS')}`;

export const useNonUniqueDocuments = (args: UseNonUniqueDocumentsArgs): [string[], () => void] => {
  const { documentKey, documents, addWhenEmpty, loading } = args;

  const initialDocuments = React.useMemo(() => {
    return documents.filter(({ key }) => key.startsWith(documentKey)).map(({ key }) => key);
  }, [JSON.stringify(documents)]);

  if (!loading && !initialDocuments.length && addWhenEmpty) {
    initialDocuments.push(getNewDocumentKey(documentKey));
  }

  const [nonUniqueDocuments, setNonUniqueDocuments] = useUpdatableState(initialDocuments);
  const addNonUniqueDocument = (): void => {
    setNonUniqueDocuments((current) => [...current, getNewDocumentKey(documentKey)]);
  };

  return [nonUniqueDocuments, addNonUniqueDocument];
};

type GetFormInputsArgs = Pick<
  OnboardingStepProps,
  'jobsiteWorker' | 'filteredDocumentTypeIds' | 'documents' | 'defaultFormValues'
> & {
  genericDocuments: string[];
  additionalCertificationsDocuments: string[];
  addAdditionalCertificationsDocument: () => void;
};

export const useIsTrainingConnectCardUpdate = <TFields extends WorkerDocumentsFormData | WorkerDocumentsDataType>(
  form: UseFormMethods<TFields>,
): void => {
  const { watch, getValues, setValue } = form;
  // TODO: remove `as string` after getting rid of `WorkerDocumentsDataType`
  const issueDate = watch?.('nyc-site-safety-training-card-issue-date') as string;

  useDidUpdateEffect(() => {
    const isTrainingConnectCardFieldName: keyof WorkerDocumentsFormData =
      'nyc-site-safety-training-card-is-training-connect-card';
    const isTrainingConnectCard = getValues(isTrainingConnectCardFieldName) ?? false;

    // only make toggle smart if it not previously set to true
    if (!isTrainingConnectCard) {
      const issueMoment = moment(issueDate, 'MM/DD/YYYY', true);
      const newIsTrainingConnectCard = issueMoment.isSameOrAfter('01/01/2022');
      const isTrainingConnectCardChanged = newIsTrainingConnectCard !== isTrainingConnectCard;

      if (isTrainingConnectCardChanged) {
        setValue(isTrainingConnectCardFieldName, newIsTrainingConnectCard, { shouldDirty: true });
      }
    }
  }, [issueDate]);
};

export const getFormInputsHook =
  (args: GetFormInputsArgs): UseInputs<WorkerDocumentsFormData> =>
  (form: UseFormMethods<WorkerDocumentsFormData>): FormInput<WorkerDocumentsFormData>[] => {
    const {
      jobsiteWorker,
      filteredDocumentTypeIds,
      documents,
      defaultFormValues,
      genericDocuments,
      additionalCertificationsDocuments,
      addAdditionalCertificationsDocument,
    } = args;
    const { watch } = form ?? {};
    const { dirtyFields } = form?.formState ?? {};

    const sstExempt = watch?.('nyc-site-safety-training-card-exempt');

    useIsTrainingConnectCardUpdate(form);

    const withFilesDocumentIds = Object.fromEntries(
      documents
        .filter(({ key: doc }) =>
          defaultFormValues.some(
            ({ name: fieldName, value }) => value && [`${doc}-front`, `${doc}-back`].includes(fieldName as string),
          ),
        )
        .map(({ key, id }) => [key, id]),
    );

    const getPreviewUrl = (documentKey: string): string => {
      return getDocumentPreviewUrl(withFilesDocumentIds[documentKey]);
    };

    const documentsWithExistingDocumentAlerts = getDocumentsWithExistingDocumentAlerts({
      stepKey: OnboardingStepKey.WorkDocuments,
      jobsiteWorker,
      documentKeys: workerDocumentsKeys,
      documents,
    });

    const getShowExistingDocumentAlert = (documentKey: string): boolean => {
      return documentsWithExistingDocumentAlerts[documentKey];
    };

    return ensureNonEmptyItems<FormInput<WorkerDocumentsFormData>>([
      ...workerDocumentsInput({
        jobsiteWorker,
        filteredDocumentTypeIds,
        suppressRequiredWhenDocIsNotDirty: true,
        dirtyFields,
        isSSTExemptVisible: true,
        sstExempt,
        getPreviewUrl,
        getShowExistingDocumentAlert,
      }),
      ...additionalCertificationsDocuments.map((documentKey) => {
        return generateJobsiteWorkerDocument({
          documentKey,
          label: 'Additional certifications',
          getPreviewUrl,
          getShowExistingDocumentAlert,
          suppressRequiredWhenDocIsNotDirty: true,
          dirtyFields,
          requiredFields: ['front'],
        });
      }),
      addAdditionalCertificationsDocument && {
        name: 'add-additional-certifications-document' as keyof WorkerDocumentsFormData,
        element: FormInputTypes.CustomContent,
        elementProps: {
          content: (
            <div onClick={addAdditionalCertificationsDocument} className={classes.additionalDocumentAdd}>
              <FilePlusIcon className="odin-mr-1.5 odin-text-odin-primary" />
              Add another certification document
            </div>
          ),
        },
        layout: '',
      },
      ...genericDocuments.map((documentKey) => {
        return generateJobsiteWorkerDocument<WorkerDocumentsFormData>({
          documentKey,
          label: 'Additional document',
          options: otherCardOptions,
          hasBack: true,
          getPreviewUrl,
        });
      }),
    ]);
  };

type DefaultFormValue = FormDefaultValue<WorkerDocumentsDataType>;

const selectOptions: Partial<
  Record<
    keyof WorkerDocumentFields<
      WorkerDocumentKey | DocumentKey.AdditionalCertifications | DocumentKey.Generic,
      SelectOptionField,
      SelectOptionElement
    >,
    SelectOptionElement[]
  >
> = {
  [`${DocumentKey.GovernmentIssuedId}-type`]: govCardOptions,
  [`${DocumentKey.NycSiteSafetyTrainingCard}-type`]: sstCardOptions,
  [`${DocumentKey.OshaCard}-type`]: oshaCardOptions,
  [`${DocumentKey.AdditionalCertifications}-card-type`]: otherCardOptions,
  [`${DocumentKey.Generic}-card-type`]: otherCardOptions,
};

export const getSelectOption = (documentKey: string, field: string, value: string): SelectOptionElement | undefined => {
  const options = Object.entries(selectOptions).find(([key]) => {
    let workerDocumentTypeKey = documentKey;
    if (documentKey.startsWith(DocumentKey.AdditionalCertifications)) {
      workerDocumentTypeKey = DocumentKey.AdditionalCertifications;
    } else if (documentKey.startsWith(DocumentKey.Generic)) {
      workerDocumentTypeKey = DocumentKey.Generic;
    }
    return key === `${workerDocumentTypeKey}-${field}`;
  })?.[1];
  return options?.find((option) => option.value === value) ?? null;
};

export function getDefaultValues({
  documents,
  additionalCertificationsDocuments,
  genericDocuments,
  defaultFormValues,
}: {
  documents: JwDocument[];
  additionalCertificationsDocuments: string[];
  genericDocuments: string[];
  defaultFormValues: DefaultFormValue[];
}): WorkerDocumentsFormData {
  const defaultValuesMap = Object.fromEntries(defaultFormValues.map(({ name, value }) => [name, value]));

  const allDocKeys = [
    ...documents.map(({ key }) => key),
    ...additionalCertificationsDocuments,
    ...genericDocuments,
  ].filter(
    (documentKey) =>
      workerDocumentsKeys.includes(documentKey as WorkerDocumentKey) ||
      documentKey.startsWith(DocumentKey.AdditionalCertifications) ||
      documentKey.startsWith(DocumentKey.Generic),
  );

  const initialValues = Object.fromEntries(
    workerDocumentsKeys
      .filter((documentKey) => !allDocKeys.includes(documentKey))
      .flatMap((documentKey) => {
        return [
          ...stringFields.map((field) => [`${documentKey}-${field}`, '']),
          ...booleanFields.map((field) => [`${documentKey}-${field}`, false]),
          ...selectOptionsFields.map((field) => [`${documentKey}-${field}`, null]),
          ...fileFields.map((field) => [`${documentKey}-${field}`, null]),
        ];
      }),
  );
  const defaultValues = Object.fromEntries(
    allDocKeys.flatMap((documentKey) => {
      return [
        ...stringFields.map((field) => [`${documentKey}-${field}`, defaultValuesMap[`${documentKey}-${field}`] ?? '']),
        ...booleanFields.map((field) => [
          `${documentKey}-${field}`,
          (defaultValuesMap[`${documentKey}-${field}`] ?? false) === 'true',
        ]),
        ...selectOptionsFields.map((field) => {
          return [
            `${documentKey}-${field}`,
            getSelectOption(documentKey, field, defaultValuesMap[`${documentKey}-${field}`]),
          ];
        }),
        ...fileFields.map((field) => [`${documentKey}-${field}`, defaultValuesMap[`${documentKey}-${field}`] ?? null]),
      ];
    }),
  );

  return { ...initialValues, ...defaultValues };
}
