import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Filter, Table, TableContainer } from '@odin-labs/components';
import { paginationSizePerPage as limit } from 'utils/constants';
import { useGetJobsiteContainerContractorsQuery } from 'apollo/generated/client-operations';
import { AuthContext } from 'auth';
import { useModalState } from 'utils';
import { LoadingError } from 'components/loadingError';
import { JobsiteTabProps } from 'containers/jobsite/types';
import { EditContractorAssignmentModal, RemoveContractorAssignmentModal } from 'containers/jobsite/modals';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { getColumns, getFilterItems } from './tables';
import { JobsiteContractor, JobsiteContractorFilters, JobsiteContractorColumn } from './types';

export function JobsiteContractorsTabContainer(props: JobsiteTabProps): ReactElement {
  const { jobsiteId, onTabApiChange } = props;
  const { currentUser: user } = React.useContext(AuthContext);
  const history = useHistory();

  const {
    value: jobsiteContractorToEdit,
    isModalOpen: isEditContractorAssignmentModalOpen,
    openModal: openEditContractorAssignmentModal,
    closeModal: closeEditContractorAssignmentModal,
    resetModalValue: resetJobsiteContractorToEdit,
  } = useModalState<JobsiteContractor>(null);

  const {
    value: jobsiteContractorToRemove,
    isModalOpen: isRemoveContractorAssignmentModalOpen,
    openModal: openRemoveContractorAssignmentModal,
    closeModal: closeRemoveContractorAssignmentModal,
    resetModalValue: resetJobsiteContractorToRemove,
  } = useModalState<JobsiteContractor>(null);

  const { page, search, updateUrl } = usePageQueryParams();
  const offset = page * limit;

  const { data, loading, error, refetch } = useGetJobsiteContainerContractorsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      jobsiteId,
      jobsiteJobsiteContractorsInput: {
        paginationInput: { limit, offset },
        search,
      },
    },
  });

  const jobsite = data?.getJobsite;

  const columns = React.useMemo<JobsiteContractorColumn[]>(
    () => getColumns({ user, openEditContractorAssignmentModal, openRemoveContractorAssignmentModal }),
    [user, openEditContractorAssignmentModal, openRemoveContractorAssignmentModal],
  );
  const filterItems = React.useMemo(() => getFilterItems({ search }), [search]);

  const onPageChangeHandler = (_pageSize: number, pageIndex: number): void => {
    updateUrl({
      page: pageIndex ? pageIndex + 1 : null,
    });
  };

  const onFiltersChangeHandler = (changedFilters: Partial<JobsiteContractorFilters>): void => {
    updateUrl({ page: null, ...changedFilters });
  };

  const onRowClickHandler = ({ data: jobsiteContractor }: { data: JobsiteContractor }): void => {
    history.push(`/contractor/${jobsiteContractor?.contractor?.contractorId}`);
  };

  const jobsiteContractors = jobsite?.jobsiteContractors.edges.map((c) => c.node) ?? [];
  const jobsiteContractorsCount = jobsite?.jobsiteContractors?.count ?? 0;
  const pageCount = Math.ceil(jobsiteContractorsCount / limit);

  React.useEffect(() => onTabApiChange({ refetchData: refetch }), [refetch]);

  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <TableContainer className="jobsite-contractors-container">
      <Filter items={filterItems} loading={loading} firstItemOnRight="search" onChange={onFiltersChangeHandler} />
      <Table
        loading={loading}
        columns={columns}
        data={jobsiteContractors}
        initialState={{ pageSize: limit }}
        pageCount={pageCount}
        pageIndex={page}
        remote
        enablePagination
        onRowClick={onRowClickHandler}
        onPageChange={onPageChangeHandler}
        disableGlobalFilter
      />
      <EditContractorAssignmentModal
        jobsiteContractor={jobsiteContractorToEdit}
        isOpen={isEditContractorAssignmentModalOpen}
        closeModal={closeEditContractorAssignmentModal}
        onClosed={resetJobsiteContractorToEdit}
        jobsite={jobsite}
        contractors={[jobsiteContractorToEdit?.contractor].filter(Boolean)}
      />
      <RemoveContractorAssignmentModal
        isOpen={isRemoveContractorAssignmentModalOpen}
        closeModal={closeRemoveContractorAssignmentModal}
        onClosed={resetJobsiteContractorToRemove}
        jobsiteContractorId={jobsiteContractorToRemove?.id}
      />
    </TableContainer>
  );
}
