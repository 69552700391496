import React, { useState, ReactElement } from 'react';
import { FormOnSubmit, ModalForm } from 'components/form';
import {
  GetJobsitesContainerCurrentSessionDocument,
  JobsiteCreateInput,
  useGetDevelopersQuery,
  useJobsiteCreateMutation,
} from 'apollo/generated/client-operations';
import { PlusIcon } from 'components/icons';
import { AlertService } from 'components/alertService';
import { nullifyEmptyFields, useIsMounted } from 'utils';
import { getGraphQLError } from 'utils/error';
import { getDevelopersOptions } from 'containers/jobsites/helpers/utils';
import { getDefaultValues, getFormInputs } from './AddJobsiteTemplateModal.forms';
import { EditJobsiteTemplateFormData, AddJobsiteTemplateModalProps } from './types';

export function AddJobsiteTemplateModal(props: AddJobsiteTemplateModalProps): ReactElement {
  const { isOpen, onCancel, onConfirm } = props;

  const isMounted = useIsMounted();
  const [isFetching, setFetching] = useState<boolean>(false);

  const { data: developersData } = useGetDevelopersQuery({ fetchPolicy: 'no-cache', skip: !isOpen });

  const [jobsiteCreate] = useJobsiteCreateMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [GetJobsitesContainerCurrentSessionDocument],
  });

  const onSubmit: FormOnSubmit<EditJobsiteTemplateFormData> = async (data, event): Promise<void> => {
    if (isFetching) return;

    setFetching(true);

    try {
      const { name, developerId } = data;

      const input: JobsiteCreateInput = nullifyEmptyFields<JobsiteCreateInput>({
        name,
        developerId: developerId?.value,
        isTemplate: true,
      });
      const result = await jobsiteCreate({ variables: { input } });

      onConfirm(result?.data?.jobsiteCreate.jobsite);
      AlertService.alert('success', 'Success', 'Jobsite template successfully created');
    } catch (error) {
      event.preventDefault();
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
    } finally {
      if (isMounted()) {
        setFetching(false);
      }
    }
  };

  const developersOptions = React.useMemo(() => {
    const developers = developersData?.getCurrentSession.user.developers.edges.map(({ node }) => node);
    return getDevelopersOptions(developers);
  }, [developersData]);

  const inputs = React.useMemo(() => getFormInputs({ developersOptions }), [developersOptions]);
  const defaultValues = React.useMemo(() => getDefaultValues(), []);

  return (
    <ModalForm
      open={isOpen}
      setOpen={onCancel}
      title="Add Jobsite Template"
      inputs={inputs}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      inputsContainerClassName="odin-grid odin-grid-cols-4 odin-gap-6"
      actionText="Add Jobsite Template"
      actionIcon={PlusIcon}
      actionButtonWithSpinner={isFetching}
    />
  );
}
