import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { DropdownButton, MenuItemProps, TabConfig } from '@odin-labs/components';
import { ensureNonEmptyItems, useBoolean } from 'utils';
import { BriefcaseIcon, WrenchIcon, LandmarkIcon, PlusIcon } from 'components/icons';
import { NewHeader } from 'components/header/NewHeader';
import { Container } from 'components/container';
import {
  GetUsersDocument,
  UserRoleKey,
  useGetRolesQuery,
  UserAssignmentType,
} from 'apollo/generated/client-operations';
import { RoutedTabsPages, useRoutedTabs } from 'components/tabs';
import { AuthContext } from 'auth';
import { AddUserModal } from './modals';
import { UsersTab, UsersTabProps, UsersTabApi } from './UsersTab';

const refetchQueries = [GetUsersDocument];

const baseRoute = `/users`;

export enum UserTabName {
  JobsiteUsers = 'jobsiteUsers',
  ContractorUsers = 'contractorUsers',
  ClientUsers = 'clientUsers',
}

export function UsersContainer(): ReactElement {
  const history = useHistory();

  const { currentUser } = React.useContext(AuthContext);
  const { isAdmin } = currentUser || {};
  const {
    value: isAddJobsiteUserModalOpen,
    setTrue: openAddJobsiteUserModal,
    setFalse: closeAddJobsiteUserModal,
  } = useBoolean(false);
  const {
    value: isAddContractorUserModalOpen,
    setTrue: openAddContractorUserModal,
    setFalse: closeAddContractorUserModal,
  } = useBoolean(false);
  const {
    value: isAddClientUserModalOpen,
    setTrue: openAddClientUserModal,
    setFalse: closeAddClientUserModal,
  } = useBoolean(false);

  const tabsConfig: TabConfig<UsersTabProps>[] = ensureNonEmptyItems([
    {
      name: UserTabName.JobsiteUsers,
      text: `Jobsite Users`,
      relativePath: '',
      component: UsersTab,
    },
    {
      name: UserTabName.ContractorUsers,
      text: `Contractor Users`,
      relativePath: '/contractor-users',
      component: UsersTab,
    },
    isAdmin && {
      name: UserTabName.ClientUsers,
      text: `Client Users`,
      relativePath: '/client-users',
      component: UsersTab,
    },
  ]);

  const { data: rolesData } = useGetRolesQuery({ fetchPolicy: 'network-only' });
  const userRoles = rolesData?.getRoles.filter(({ key }) => key !== UserRoleKey.Worker);

  const [tabApi, setTabApi] = React.useState<UsersTabApi>(null);

  const tabsCount: Record<string, string> = {
    [UserTabName.JobsiteUsers]: tabApi?.jobsiteUsersCount?.toString() ?? '...',
    [UserTabName.ContractorUsers]: tabApi?.contractorUsersCount?.toString() ?? '...',
    [UserTabName.ClientUsers]: tabApi?.developerUsersCount?.toString() ?? '...',
  };

  const tabsConfigWithBadges = tabsConfig.map((tab) => ({ ...tab, badge: tabsCount[tab.name] }));
  const { tabs, currentTab } = useRoutedTabs({ tabsConfig: tabsConfigWithBadges, baseRoute });

  const navigateToTabIfNeeded = (tabName: UserTabName): void => {
    if (currentTab.name !== tabName) {
      const tab = tabsConfig.find((t) => t.name === tabName);
      history.push(`${baseRoute}${tab.relativePath}`);
    }
  };

  const closeModalAndNavigateToJobsiteUsersIfNeeded = (): void => {
    navigateToTabIfNeeded(UserTabName.JobsiteUsers);
    closeAddJobsiteUserModal();
  };

  const closeModalAndNavigateToContractorUsersIfNeeded = (): void => {
    navigateToTabIfNeeded(UserTabName.ContractorUsers);
    closeAddContractorUserModal();
  };

  const closeModalAndNavigateToClientUsersIfNeeded = (): void => {
    navigateToTabIfNeeded(UserTabName.ClientUsers);
    closeAddClientUserModal();
  };

  const menuItems: MenuItemProps[] = ensureNonEmptyItems([
    {
      onClick: openAddJobsiteUserModal,
      text: 'Jobsite User',
      icon: BriefcaseIcon,
    },
    {
      onClick: openAddContractorUserModal,
      text: 'Contractor User',
      icon: WrenchIcon,
    },
    isAdmin && {
      onClick: openAddClientUserModal,
      text: 'Client User',
      icon: LandmarkIcon,
    },
  ]);

  let userAssignmentType: UserAssignmentType;
  switch (currentTab.name) {
    case UserTabName.ContractorUsers:
      userAssignmentType = UserAssignmentType.Contractor;
      break;
    case UserTabName.ClientUsers:
      userAssignmentType = UserAssignmentType.Developer;
      break;
    default:
      userAssignmentType = UserAssignmentType.Jobsite;
      break;
  }

  const tabsPageProps: UsersTabProps = {
    onTabApiChange: setTabApi,
    userRoles,
    userAssignmentType,
  };

  return (
    <Container className="users-container">
      <NewHeader
        title="Users"
        tabsProps={{ tabs, currentTab }}
        actionsProps={{
          onReloadPressed: tabApi?.refetchData,
          children: !!menuItems.length && <DropdownButton menuItems={menuItems} icon={PlusIcon} text="Add" />,
        }}
      />
      <RoutedTabsPages baseRoute={baseRoute} tabs={tabs} componentProps={tabsPageProps} />
      <AddUserModal
        isOpen={isAddJobsiteUserModalOpen}
        onCancel={closeAddJobsiteUserModal}
        onConfirm={closeModalAndNavigateToJobsiteUsersIfNeeded}
        refetchQueries={refetchQueries}
        userRoleType={UserAssignmentType.Jobsite}
        userRoles={userRoles}
      />
      <AddUserModal
        isOpen={isAddContractorUserModalOpen}
        onCancel={closeAddContractorUserModal}
        onConfirm={closeModalAndNavigateToContractorUsersIfNeeded}
        refetchQueries={refetchQueries}
        userRoleType={UserAssignmentType.Contractor}
        userRoles={userRoles}
      />
      {isAdmin && (
        <AddUserModal
          isOpen={isAddClientUserModalOpen}
          onCancel={closeAddClientUserModal}
          onConfirm={closeModalAndNavigateToClientUsersIfNeeded}
          refetchQueries={refetchQueries}
          userRoleType={UserAssignmentType.Developer}
          userRoles={userRoles}
        />
      )}
    </Container>
  );
}
