import { useQuery } from '@apollo/client';
import {
  JobsiteWorkerDocument,
  JobsiteWorkerDocumentVersion,
  QueryGetWorkerDocumentsArgs,
  WorkerDocumentLevel,
} from 'apollo/generated/client-operations';
import React, { ReactElement } from 'react';
import { Button, Slideover, Table, TableContainer } from '@odin-labs/components';
import { GetWorkerDocumentsResponse, JobsiteWorker, WorkerTabProps } from 'containers/worker/types';
import { useBoolean, useResettableState, useUpdatableState } from 'utils';
import { AuthContext } from 'auth';
import { to } from 'acl';
import { JobsiteWorkerDocumentCard } from 'components/cards/JobsiteWorkerDocumentCard';
import { SelectDocumentTypeModal } from 'components/modals/SelectDocumentTypeModal';
import { GET_WORKER_DOCUMENTS } from 'containers/worker/helpers/queries';
import { DocumentKey, byCreatedAtDescending } from 'containers/worker/utils';
import { WorkerDocumentFormModalContainer } from 'containers/modals/WorkerDocumentFormModalContainer';
import { LockedWorkerAlert } from 'containers/worker/tabs/LockedWorkerAlert';
import { NewHeader } from 'components/header/NewHeader';
import { PlusIcon } from 'components/icons';
import { DocumentData, getColumns, toDocumentData } from './WorkerDocuments.tables';

const uploadDocumentTypes: Partial<Record<DocumentKey, string>> = {
  [DocumentKey.OshaCard]: 'OSHA Card',
  [DocumentKey.NycSiteSafetyTrainingCard]: 'NYC Site Safety Training Card',
  [DocumentKey.GovernmentIssuedId]: 'Government Issued ID',
  [DocumentKey.LIRRBlueTraining]: 'LIRR Blue Training',
  [DocumentKey.ConfinedSpacesTraining]: 'Confined Spaces Training',
  [DocumentKey.SiteSpecificOrientation]: 'Site Specific Orientation or Refresher Training',
  [DocumentKey.AdditionalCertifications]: 'Additional Certifications',
  [DocumentKey.Generic]: 'Other',
};

const documentsAllowingNewVersion = [
  DocumentKey.OshaCard,
  DocumentKey.NycSiteSafetyTrainingCard,
  DocumentKey.GovernmentIssuedId,
  DocumentKey.Generic,
  DocumentKey.AdditionalCertifications,
  DocumentKey.SiteSpecificOrientation,
  DocumentKey.LIRRBlueTraining,
  DocumentKey.ConfinedSpacesTraining,
];

const isDocumentEditable = (documentKey: string): boolean => {
  return Object.keys(uploadDocumentTypes).includes(documentKey);
};

export function WorkerDocuments(props: WorkerTabProps): ReactElement {
  const { worker, jobsiteWorkers, jobsiteIds, developerIds, isLocked, refetchWorkerData, onTabApiChange } = props;
  const { currentUser: user } = React.useContext(AuthContext);
  const isContractorUser = user.isContractor;

  const {
    value: isDocumentTypeModalOpen,
    setTrue: openDocumentTypeModal,
    setFalse: closeDocumentTypeModal,
  } = useBoolean(false);

  const {
    value: addDocumentVersionModalState,
    setValue: openAddDocumentVersionModal,
    resetValue: closeAddDocumentVersionModal,
  } = useResettableState<{ makeNewDocument: boolean }>(null);
  const isAddDocumentVersionModalOpen = !!addDocumentVersionModalState;

  const {
    value: selectedDocumentId,
    setValue: openSlideover,
    resetValue: closeSlideover,
  } = useResettableState<string>(null);
  const isDocumentSliderVisible = !!selectedDocumentId;

  const { data: workerDocumentsData, loading: workerDocumentsLoading } = useQuery<
    GetWorkerDocumentsResponse,
    QueryGetWorkerDocumentsArgs
  >(GET_WORKER_DOCUMENTS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      workerId: worker?.workerId,
      includePhi: false,
      includeArchived: worker?.isArchived,
    },
    skip: !worker || worker?.isArchived || isLocked,
  });

  React.useEffect(() => onTabApiChange({ refetchData: refetchWorkerData }), [refetchWorkerData]);

  const columns = React.useMemo(() => getColumns(), []);

  const workerDocuments = React.useMemo(() => {
    const filteredDocuments = workerDocumentsData?.getWorkerDocuments?.workerDocuments?.filter(
      (document: JobsiteWorkerDocument) => {
        const { jobsiteWorkerDocumentType } = document ?? {};
        const { workerDocumentType, jobsite } = jobsiteWorkerDocumentType ?? {};
        const { key: documentKey, documentLevel } = workerDocumentType ?? {};

        if (documentKey && !documentKey.includes('medical') && !documentKey.includes('post-employment')) {
          if (documentLevel === WorkerDocumentLevel.Organization) {
            return developerIds.includes(jobsite?.developer?.developerId);
          }
          return workerDocumentType?.isGlobal || jobsiteIds.includes(jobsite?.jobsiteId);
        }
        return false;
      },
    );
    return filteredDocuments?.map(toDocumentData) ?? [];
  }, [workerDocumentsData, jobsiteIds, developerIds]);

  const selectedDocument = workerDocuments.find(
    (d) => d.document.jobsiteWorkerDocumentId === selectedDocumentId,
  )?.document;

  const { name: selectedDocumentName, key: selectedDocumentKey } =
    selectedDocument?.jobsiteWorkerDocumentType?.workerDocumentType ?? {};

  const { jobsiteWorkerDocumentVersions } = selectedDocument ?? {};
  const documentVersions =
    jobsiteWorkerDocumentVersions && [...jobsiteWorkerDocumentVersions].sort(byCreatedAtDescending);

  const [jobsiteWorkerDocumentTypeKey, setJobsiteWorkerDocumentTypeKey] = useUpdatableState(selectedDocumentKey);
  const [selectedJobsiteWorker, setSelectedJobsiteWorker] = useUpdatableState<JobsiteWorker>(
    selectedDocument?.jobsiteWorker,
  );

  if (isLocked) {
    return <LockedWorkerAlert worker={worker} />;
  }

  const onRowClickHandler = ({ data: documentData }: { data: DocumentData }): void => {
    openSlideover(documentData.document.jobsiteWorkerDocumentId);
  };

  const canUploadNewVersion =
    documentsAllowingNewVersion.includes(selectedDocumentKey as DocumentKey) &&
    user.isAllowed(to.uploadDocumentVersion) &&
    !isContractorUser;

  const getFooter = React.useCallback((): ReactElement | null => {
    return canUploadNewVersion ? (
      <div className="odin-p-5">
        <Button
          text="Upload new version"
          className="odin-w-full odin-justify-center"
          onClick={(): void => {
            openAddDocumentVersionModal({ makeNewDocument: false });
          }}
        />
      </div>
    ) : null;
  }, [canUploadNewVersion, openAddDocumentVersionModal]);

  return (
    <>
      <TableContainer>
        <NewHeader
          size="md"
          title="Documents"
          titleInfo={workerDocuments?.length ?? '...'}
          actionsProps={{
            headerActions: null,
            children: user.isAllowed(to.addWorkerDocuments) && !!jobsiteIds.length && !isContractorUser && (
              <Button
                size={['base', 'md:xs']}
                text="Add Document"
                hideTextOnMobile
                icon={PlusIcon}
                onClick={openDocumentTypeModal}
              />
            ),
          }}
        />
        <Table
          loading={workerDocumentsLoading}
          columns={columns}
          data={workerDocuments}
          disableGlobalFilter
          disableSortBy
          initialState={{ pageSize: 50 }}
          // cellClassName="!odin-pl-5"
          onRowClick={onRowClickHandler}
          noResultsText="No work documents to show"
        />
      </TableContainer>
      <Slideover
        title="Documents"
        open={isDocumentSliderVisible}
        setOpen={closeSlideover}
        footer={getFooter}
        // afterLeave={(): void => setSelectedDocumentId('')}
        overlayCloseEnabled={!isAddDocumentVersionModalOpen}
      >
        <>
          <h3>{selectedDocumentName}</h3>
          <hr />
          {documentVersions?.length > 0 && (
            <>
              <div className="odin-text-1.5xs odin-text-slate-500 odin-mt-2 odin-mb-1">Active version</div>
              <JobsiteWorkerDocumentCard
                isActive
                isEditable={isDocumentEditable(selectedDocumentKey)}
                key={documentVersions?.[0]?.jobsiteWorkerDocumentVersionId}
                jobsiteWorkerDocumentVersion={documentVersions?.[0]}
                jobsiteWorkerDocument={selectedDocument}
              />
              {documentVersions.length > 1 && (
                <>
                  <hr />
                  <div className="odin-text-1.5xs odin-text-slate-500 odin-mt-2 odin-mb-1">Document history</div>
                  {documentVersions?.slice(1)?.map(
                    (documentVersion: JobsiteWorkerDocumentVersion): ReactElement => (
                      <JobsiteWorkerDocumentCard
                        key={documentVersion?.jobsiteWorkerDocumentVersionId}
                        jobsiteWorkerDocumentVersion={documentVersion}
                        jobsiteWorkerDocument={selectedDocument}
                      />
                    ),
                  )}
                </>
              )}
              <hr />
            </>
          )}
        </>
      </Slideover>
      <WorkerDocumentFormModalContainer
        isModalOpen={isAddDocumentVersionModalOpen}
        jobsiteId={selectedJobsiteWorker?.jobsiteContractor?.jobsite?.jobsiteId}
        jobsiteWorker={selectedJobsiteWorker}
        jobsiteWorkerDocument={selectedDocument}
        jobsiteWorkerDocumentTypeKey={jobsiteWorkerDocumentTypeKey}
        setModalOpen={closeAddDocumentVersionModal}
        makeNewDocument={addDocumentVersionModalState?.makeNewDocument}
      />
      <SelectDocumentTypeModal
        isOpen={isDocumentTypeModalOpen}
        jobsiteWorkers={jobsiteWorkers}
        documentTypes={uploadDocumentTypes}
        onCancel={closeDocumentTypeModal}
        onConfirm={({ documentType, jobsiteWorker }): void => {
          setJobsiteWorkerDocumentTypeKey(documentType);
          setSelectedJobsiteWorker(jobsiteWorker);
          closeDocumentTypeModal();
          openAddDocumentVersionModal({ makeNewDocument: documentType === DocumentKey.SiteSpecificOrientation });
        }}
      />
    </>
  );
}
