import React, { ReactElement, useState } from 'react';
import { Moment } from 'moment';
import cn from 'classnames';
import { useMutation } from '@apollo/client';
import {
  AdditionalFieldValue,
  MutationUploadSingleFileArgs,
  useCreateJobsiteWorkerDocumentMutation,
  useCreateJobsiteWorkerDocumentVersionMutation,
  useUpdateJobsiteWorkerMutation,
} from 'apollo/generated/client-operations';
import { ServerFile } from 'types';
import { AuthContext } from 'auth';
import { FilePlusIcon } from 'components/icons';
import { AlertService } from 'components/alertService';
import { Form, FormOnSubmit } from 'components/form';
import { Header } from 'components/header';
import { FILE_UPLOAD } from 'containers/workerOnboarding/helpers/queries';
import { JobsiteWorkerOnboardingFooterNavbar } from 'containers/workerOnboarding/navbar/JobsiteWorkerOnboardingFooterNavbar';
import { getVisibleDocuments } from 'containers/workerOnboarding/helpers/utils';
import { OnboardingStepProps, UploadFileResponse, WorkerDocumentsDataType } from 'containers/workerOnboarding/types';
import { isServerFile, uploadDFilesDistinctly, useDeferredFormSubmission, useIsMounted } from 'utils';
import { getCurrentISOFormattedDateTime, getISOFormattedDate } from 'utils/dates';
import { getGraphQLError } from 'utils/error';
import { DocumentKey } from 'containers/worker/utils';
import { StepLoading } from './StepLoading';
import {
  workerDocumentsKeys,
  getDefaultValues,
  getFormInputsHook,
  useNonUniqueDocuments,
  WorkerDocumentsFormData,
  getDocumentDataValue,
  getDataValueFunction,
} from './WorkDocumentsStep.forms';
import { classes } from './WorkDocumentsStep.style';
import { commonClasses } from './common.style';

export function WorkDocumentsStep(props: OnboardingStepProps): ReactElement {
  const {
    loading: parentLoading,
    jobsiteWorkerId,
    jobsiteWorker,
    defaultFormValues,
    filteredDocumentTypeIds,
    documents,
    refetchJobsiteWorkerDocuments,
  } = props;

  const visibleDocuments = getVisibleDocuments(jobsiteWorker);
  const isDocumentTypeVisible = (documentKey: DocumentKey): boolean => {
    return filteredDocumentTypeIds[documentKey] && visibleDocuments?.includes(documentKey);
  };

  const { currentUser: user } = React.useContext(AuthContext);
  const isContractorMember = user.isContractor;

  const isMounted = useIsMounted();
  const [fetching, setFetching] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const { formRef, deferredSubmission, submitForm } = useDeferredFormSubmission<WorkerDocumentsDataType>();

  const [genericDocuments, addGenericDocument] = useNonUniqueDocuments({
    documentKey: DocumentKey.Generic,
    documents,
    loading: parentLoading,
  });

  const [additionalCertificationsDocuments, addAdditionalCertificationsDocument] = useNonUniqueDocuments({
    documentKey: DocumentKey.AdditionalCertifications,
    documents,
    addWhenEmpty: isDocumentTypeVisible(DocumentKey.AdditionalCertifications),
    loading: parentLoading,
  });

  const [updateJobsiteWorker] = useUpdateJobsiteWorkerMutation({
    onCompleted: () => {
      if (isMounted()) setFetching(false);
      refetchJobsiteWorkerDocuments();
    },
    onError: (error) => {
      setFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
    refetchQueries: ['GetJobsiteWorker'],
  });

  const [uploadFile] = useMutation<UploadFileResponse, MutationUploadSingleFileArgs>(FILE_UPLOAD, {
    onError: (error) => {
      setFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const [createJobsiteWorkerDocument] = useCreateJobsiteWorkerDocumentMutation({
    onError: (error) => {
      setFetching(false);
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    },
  });

  const [createJobsiteWorkerDocumentVersion] = useCreateJobsiteWorkerDocumentVersionMutation();

  const getDocumentTypeData = (documentKey: string): { documentTypeKey: string; documentTypeId: string } => {
    let documentTypeKey = documentKey;
    if (documentKey.includes(DocumentKey.Generic)) {
      documentTypeKey = DocumentKey.Generic;
    } else if (documentKey.includes(DocumentKey.AdditionalCertifications)) {
      documentTypeKey = DocumentKey.AdditionalCertifications;
    }
    return {
      documentTypeKey,
      documentTypeId: filteredDocumentTypeIds[documentTypeKey],
    };
  };

  const onSubmit: FormOnSubmit<WorkerDocumentsFormData> = async (data, _error, dirtyFields): Promise<void> => {
    if (fetching) return;
    setFetching(true);

    try {
      const dirtyFieldKeys = Object.keys(dirtyFields);

      let allDocKeys = [...workerDocumentsKeys, ...additionalCertificationsDocuments, ...genericDocuments];
      const sstExempt = getDocumentDataValue(data, DocumentKey.NycSiteSafetyTrainingCard, 'exempt');
      const exemptReason = sstExempt
        ? getDocumentDataValue(data, DocumentKey.NycSiteSafetyTrainingCard, 'exemption-reason')
        : null;

      if (sstExempt) {
        allDocKeys = allDocKeys.filter((key: string) => key !== DocumentKey.NycSiteSafetyTrainingCard);
      }

      const filesToUpload = allDocKeys
        .flatMap((documentKey) => [
          { fieldName: `${documentKey}-front`, file: getDocumentDataValue(data, documentKey, 'front') },
          { fieldName: `${documentKey}-back`, file: getDocumentDataValue(data, documentKey, 'back') },
        ])
        .filter(
          (docFile): docFile is { fieldName: string; file: File } =>
            docFile.file && !(docFile.file as ServerFile).fileId,
        );
      const uploadedFiles = await uploadDFilesDistinctly(filesToUpload, uploadFile);

      await Promise.all(
        allDocKeys.map(async (documentKey) => {
          const isDirty = dirtyFieldKeys.some((dirtyFieldKey) => dirtyFieldKey.startsWith(`${documentKey}-`));
          const fileIds = [];
          const additionalFieldValues: (AdditionalFieldValue & { type: string })[] = [];

          if (!isDirty) {
            return;
          }

          const getDataValue = getDataValueFunction(data, documentKey);

          const frontFile = getDataValue('front');
          if (frontFile) {
            if (isServerFile(frontFile)) {
              fileIds.push(frontFile.fileId);
            } else {
              const { fileId: frontFileId } = uploadedFiles[`${documentKey}-front`] ?? {};
              if (frontFileId) {
                fileIds.push(frontFileId);
              }
            }
          }

          const backFile = getDataValue('back');
          if (backFile) {
            if (isServerFile(backFile)) {
              fileIds.push(backFile.fileId);
            } else {
              const { fileId: frontFileId } = uploadedFiles[`${documentKey}-front`] ?? {};
              const { fileId: backFileId } = uploadedFiles[`${documentKey}-back`] ?? {};
              if (backFileId && backFileId !== frontFileId) {
                fileIds.push(backFileId);
              }
            }
          }

          const expirationDateRaw = getDataValue('expiration-date');
          if (expirationDateRaw) {
            const expirationDate = getISOFormattedDate(expirationDateRaw);
            additionalFieldValues.push({ key: 'expiration-date', value: expirationDate, type: 'date' });
          }

          const issueDateRaw = getDataValue('issue-date');
          if (issueDateRaw) {
            const issueDate = getISOFormattedDate(issueDateRaw);
            additionalFieldValues.push({ key: 'issue-date', value: issueDate, type: 'date' });
          }

          const docNumber = getDataValue('number');
          if (docNumber) {
            additionalFieldValues.push({ key: 'number', value: docNumber, type: 'string' });
          }

          const docType = getDataValue('type');
          if (docType) {
            additionalFieldValues.push({ key: 'type', value: docType.value, type: 'string' });
          }

          const cardType = getDataValue('card-type');
          if (cardType) {
            additionalFieldValues.push({ key: 'card-type', value: cardType.value, type: 'string' });
          }

          const stateIssued = getDataValue('state-issued');
          if (stateIssued) {
            additionalFieldValues.push({ key: `state-issued`, value: stateIssued.value, type: 'string' });
          }

          const isTrainingConnectCard = getDataValue('is-training-connect-card');
          if (isTrainingConnectCard !== undefined) {
            additionalFieldValues.push({
              key: 'is-training-connect-card',
              value: isTrainingConnectCard.toString(),
              type: 'string',
            });
          }

          const jobsiteWorkerDocumentId = documents.find(({ key }) => key === documentKey)?.id;

          if (jobsiteWorkerDocumentId) {
            await createJobsiteWorkerDocumentVersion({
              variables: {
                jobsiteWorkerDocumentId,
                jobsiteWorkerDocumentVersionInput: {
                  fileIds,
                  additionalFieldValues,
                },
              },
            });
          } else {
            const { documentTypeKey, documentTypeId } = getDocumentTypeData(documentKey);
            await createJobsiteWorkerDocument({
              variables: {
                jobsiteWorkerDocumentInput: {
                  jobsiteWorkerId,
                  jobsiteWorkerDocumentTypeId: documentTypeId,
                  key: documentTypeKey,
                },
                jobsiteWorkerDocumentVersionInput: {
                  fileIds,
                  additionalFieldValues,
                },
              },
            });
          }
        }),
      );

      await updateJobsiteWorker({
        variables: {
          jobsiteWorkerId,
          jobsiteWorkerInput: {
            documentsCompletedAt: getCurrentISOFormattedDateTime(),
            documentsCompletedSkipReason: null,
            nycSstExemptionReason: sstExempt ? exemptReason : null,
          },
        },
      });

      deferredSubmission.resolve(true);
    } catch (error) {
      AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      deferredSubmission.reject(error);
    }
  };

  const overrideComplete = (reason: string, expirationDate: Moment, exemptionReason: string): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          documentsCompletedAt: getCurrentISOFormattedDateTime(),
          documentsCompletedSkipReason: reason,
          documentsCompletedSkipExpiresAt: expirationDate.toDate(),
          documentExemptionReason: exemptionReason,
        },
      },
    });
  };

  const resetComplete = (): void => {
    if (fetching) return;
    setFetching(true);

    updateJobsiteWorker({
      variables: {
        jobsiteWorkerId,
        jobsiteWorkerInput: {
          documentsCompletedAt: null,
          documentsCompletedSkipReason: null,
        },
      },
    });
  };

  const defaultValues = React.useMemo(
    () => getDefaultValues({ documents, additionalCertificationsDocuments, genericDocuments, defaultFormValues }),
    [
      documents,
      defaultFormValues,
      // `additionalCertificationsDocuments` and `genericDocuments` are not specified as dependencies
      // to not trigger `defaultValues` recalculation
    ],
  );

  const inputs = getFormInputsHook({
    jobsiteWorker,
    defaultFormValues,
    filteredDocumentTypeIds,
    documents,
    genericDocuments,
    additionalCertificationsDocuments,
    addAdditionalCertificationsDocument: isDocumentTypeVisible(DocumentKey.AdditionalCertifications)
      ? addAdditionalCertificationsDocument
      : undefined,
  });

  const loading = parentLoading || fetching;

  return (
    <>
      <div className="odin-relative">
        <fieldset disabled={!!isContractorMember}>
          <Header title="Work documents" />
          <StepLoading loading={loading} hasTransparentBackground={!(inputs() as Array<unknown>).length} />
          <Form
            inputs={inputs}
            ref={formRef}
            defaultValues={defaultValues}
            onIsDirtyChange={setIsFormDirty}
            onSubmit={onSubmit}
            className={commonClasses.form}
            inputsContainerClassName={commonClasses.formInputsContainer}
          />
          {isDocumentTypeVisible(DocumentKey.Generic) && (
            <div
              onClick={addGenericDocument}
              className={cn(classes.additionalDocumentAdd, !genericDocuments.length && 'odin-mt-4')}
            >
              <FilePlusIcon className="odin-mr-1.5 odin-text-odin-primary" />
              Add other document
            </div>
          )}
        </fieldset>
      </div>
      <JobsiteWorkerOnboardingFooterNavbar
        jobsiteWorker={jobsiteWorker}
        isFormDirty={isFormDirty}
        onSave={submitForm}
        onResetComplete={resetComplete}
        onForceComplete={overrideComplete}
        hideLockSession
        showConditionalPassExpiration
        showExemptionReason
        hideSave={isContractorMember}
        hideSkipOptions={isContractorMember}
      />
    </>
  );
}
