import cn from 'classnames';

export const classes = {
  dropzone: cn('dropzone odin-relative odin-space-y-2'),
  dropzoneArea: ({ isAvatar, disabled }: { isAvatar?: boolean; disabled?: boolean }): string =>
    cn(
      'dropzone__area',
      'odin-bg-white odin-rounded-md',
      !disabled && 'odin-cursor-pointer',
      !isAvatar && 'odin-border odin-border-dashed odin-border-gray-300',
      'focus:odin-outline-none focus:odin-ring-offset-2',
      'focus:odin-shadow-odin-focus-ring',
    ),
  dropzoneFiles: cn('dropzone__files odin-flex odin-gap-x-4'),
  dropzoneLabel: cn('dropzone__label odin-text-gray-900 odin-text-sm odin-font-medium'),
  skeleton: cn(
    'odin-absolute odin-z-10 odin-flex-none odin-animate-pulse',
    'odin-w-full odin-h-full odin-bg-gray-300 odin-rounded-md',
  ),
};
