import { UserRoleKey } from 'apollo/generated/client-operations';
import { Auth, User, userHasSomeRoleOtherThan } from './utils';

export type { Auth, User };

export enum to {
  banWorkers = 'banWorkers',
  unbanWorkers = 'unbanWorkers',
  suspendWorkers = 'suspendWorkers',
  unsuspendWorkers = 'unsuspendWorkers',
  offboardWorkers = 'offboardWorkers',
  deactivateBadges = 'deactivateBadges',
  addWorkers = 'addWorkers',
  addDevelopers = 'addDevelopers',
  editDevelopers = 'editDevelopers',
  addOrganizations = 'addOrganizations',
  editOrganizations = 'editOrganizations',
  issueTempBadges = 'issueTempBadges',
  addBadges = 'addBadges',
  reassignWorkers = 'reassignWorkers',
  addContractors = 'addContractors',
  editContractors = 'editContractors',
  editContractorPayments = 'editContractorPayments',
  generateContractorInvoices = 'generateContractorInvoices',
  addContractorJobsiteAssignments = 'addContractorJobsiteAssignments',
  editContractorJobsiteAssignments = 'editContractorJobsiteAssignments',
  removeContractorJobsiteAssignments = 'removeContractorJobsiteAssignments',
  seeContractorUsers = 'seeContractorUsers',
  addContractorUsers = 'addContractorUsers',
  editContractorUsers = 'editContractorUsers',
  removeContractorUsers = 'removeContractorUsers',
  removeContractorWorkers = 'removeContractorWorkers',
  editMetadata = 'editMetadata',
  changeContractor = 'changeContractor',
  correctJobsite = 'correctJobsite',
  debugWorkerAccess = 'debugWorkerAccess',
  editJobsiteAccess = 'editJobsiteAccess',
  enableJobsiteWorkerSelfOnboarding = 'enableJobsiteWorkerSelfOnboarding',
  seeAllowEscortPrivileges = 'seeAllowEscortPrivileges',
  seeExemptFrom30DaysOfInactivity = 'seeExemptFrom30DaysOfInactivity',
  seePayrollID = 'seePayrollID',
  seeExemptFromSiteRefresherTraining = 'seeExemptFromSiteRefresherTraining',
  accessPhiDocuments = 'accessPhiDocuments',
  redirectToDashboard = 'redirectToDashboard',
  deleteJobsiteAnnouncement = 'deleteJobsiteAnnouncement',
  addJobsiteAnnouncements = 'addJobsiteAnnouncements',
  accessWorkerReportMedicalDocuments = 'accessWorkerReportMedicalDocuments',
  seeSiteSpecificOrientationStep = 'seeSiteSpecificOrientationStep',
  seeMedicalTestingStepContent = 'seeMedicalTestingStepContent',
  addUnions = 'addUnions',
  chooseToDeleteJobsiteAssignment = 'chooseToDeleteJobsiteAssignment',
  seeCorrectionOrientationOption = 'seeCorrectionOrientationOption',
  searchForWorker = 'searchForWorker',
  runWorkerActionsFromSearch = 'runWorkerActionsFromSearch',
  queryForUserData = 'queryForUserData',
  manageJobsiteAccessEvents = 'manageJobsiteAccessEvents',
  deleteAccessEvents = 'deleteAccessEvents',
  archiveAccessEvents = 'archiveAccessEvents',
  accessWorkerAdminSection = 'accessWorkerAdminSection',
  seeWorkerCCureData = 'seeWorkerCCureData',
  addWorkerDocuments = 'addWorkerDocuments',
  uploadDocumentVersion = 'uploadDocumentVersion',
  accessWorkerMedicalDocuments = 'accessWorkerMedicalDocuments',
  addWorkerMedicalDocuments = 'addWorkerMedicalDocuments',
  viewWorkerIDs = 'viewWorkerIDs',
  editWorkerPersonalInfo = 'editWorkerPersonalInfo',
  assignWorkers = 'assignWorkers',
  seeWorkerJobsiteActions = 'seeWorkerJobsiteActions',
  seeBadgeActions = 'seeBadgeActions',
  manageWorkerOnboardingLinks = 'manageWorkerOnboardingLinks',
  addJobsites = 'addJobsites',
  closeOutJobsites = 'closeOutJobsites',
  archiveJobsites = 'archiveJobsites',
  seeJobsiteTemplates = 'seeJobsiteTemplates',
  addJobsiteTemplates = 'addJobsiteTemplates',
  seeJobsiteUsers = 'seeJobsiteUsers',
  addJobsiteUsers = 'addJobsiteUsers',
  removeJobsiteUsers = 'removeJobsiteUsers',
  editJobsiteConfiguration = 'editJobsiteConfiguration',
  seeWorkerProfileOverview = 'seeWorkerProfileOverview',
  seeWorkerProfileJobsites = 'seeWorkerProfileJobsites',
  seeWorkerProfileDocuments = 'seeWorkerProfileDocuments',
  seeWorkerProfileMedical = 'seeWorkerProfileMedical',
  seeWorkerProfileBadges = 'seeWorkerProfileBadges',
  seeWorkerProfileAccessActivity = 'seeWorkerProfileAccessActivity',
  seeWorkerProfileTimecards = 'seeWorkerProfileTimecards',
  seeWorkerProfileFormSubmissions = 'seeWorkerProfileFormSubmissions',
  seeWorkerProfileComments = 'seeWorkerProfileComments',
  seeWorkerProfileActions = 'seeWorkerProfileActions',
  seeWorkerProfileUserRoles = 'seeWorkerProfileUserRoles',
  generateWalletWorkerCard = 'generateWalletWorkerCard',
  changeAuthenticatedWorkerCredentials = 'changeAuthenticatedWorkerCredentials',
  seeChanges = 'seeChanges',
  editFormSubmissions = 'editFormSubmissions',
  removeFormSubmissions = 'removeFormSubmissions',
}

/** Permission assignable for a specific object */
export type ObjectPermission = Extract<
  to,
  | to.banWorkers
  | to.unbanWorkers
  | to.suspendWorkers
  | to.unsuspendWorkers
  | to.offboardWorkers
  | to.editMetadata
  | to.accessPhiDocuments
  | to.deleteJobsiteAnnouncement
  | to.addJobsiteAnnouncements
  | to.seeSiteSpecificOrientationStep
  | to.seeMedicalTestingStepContent
  | to.accessWorkerMedicalDocuments
  | to.seeContractorUsers
  | to.addContractorUsers
  | to.editContractorUsers
  | to.editContractorPayments
  | to.generateContractorInvoices
  | to.removeContractorUsers
  | to.seeJobsiteUsers
  | to.addJobsiteUsers
  | to.removeJobsiteUsers
>;

/* 
// if you need to check if a permission is a JobsitePermission,
// uncomment the below code and comment the above statement
export const objectPermissions = [
  to.banWorkers,
  to.unbanWorkers,
  to.suspendWorkers,
  to.unsuspendWorkers,
  to.offboardWorkers,
  to.editMetadata,
  to.accessPhiDocuments,
  to.deleteJobsiteAnnouncement,
  to.addJobsiteAnnouncement,
  to.seeSiteSpecificOrientationStep,
  to.seeMedicalTestingStepContent,
  to.accessWorkerMedicalDocuments,
] as const;

export type ObjectPermission = typeof objectPermissions[number];

export const isObjectPermission = (permission: to): boolean =>
  objectPermissions.includes(permission as ObjectPermission);
*/

export type GeneralPermission = Exclude<to, ObjectPermission>;

export type ObjectPermissionFn = (user: User, jobsiteId?: string) => boolean;
export type GeneralPermissionFn = (user: User) => boolean;

export type PermissionFn<T extends to> = T extends ObjectPermission ? ObjectPermissionFn : GeneralPermissionFn;

export const permissions: { [key in to]: UserRoleKey[] | PermissionFn<key> } = {
  [to.banWorkers]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSecuritySupervisor,
    UserRoleKey.JobsiteMedicSupervisor,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
  ],
  [to.unbanWorkers]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSecuritySupervisor,
    UserRoleKey.JobsiteMedicSupervisor,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
  ],
  [to.suspendWorkers]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSecurity,
    UserRoleKey.JobsiteSecuritySupervisor,
    UserRoleKey.JobsiteMedicSupervisor,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
  ],
  [to.unsuspendWorkers]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSecurity,
    UserRoleKey.JobsiteSecuritySupervisor,
    UserRoleKey.JobsiteMedicSupervisor,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
  ],
  [to.offboardWorkers]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSecurity,
    UserRoleKey.JobsiteSecuritySupervisor,
    UserRoleKey.JobsiteMedicSupervisor,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
  ],
  [to.deactivateBadges]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
    UserRoleKey.JobsiteSecurity,
    UserRoleKey.JobsiteSecuritySupervisor,
  ],
  [to.addWorkers]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSecurity,
    UserRoleKey.JobsiteSecuritySupervisor,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
    UserRoleKey.JobsiteMedicSupervisor,
  ],
  [to.issueTempBadges]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
    UserRoleKey.JobsiteSecurity,
    UserRoleKey.JobsiteSecuritySupervisor,
  ],
  [to.addBadges]: (user: User): boolean =>
    userHasSomeRoleOtherThan(user, [
      UserRoleKey.Worker,
      UserRoleKey.JobsiteMedicSupervisor,
      UserRoleKey.JobsiteReader,
      UserRoleKey.JobsiteDirectoryReader,
      UserRoleKey.JobsiteEditor,
    ]),
  [to.seeBadgeActions]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteEditor,
    UserRoleKey.JobsiteSecurity,
    UserRoleKey.JobsiteSecuritySupervisor,
    UserRoleKey.JobsiteSiteSafetyManager,
  ],
  [to.reassignWorkers]: [UserRoleKey.Admin],
  [to.addContractors]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  [to.editContractors]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  [to.editContractorPayments]: [UserRoleKey.Admin, UserRoleKey.ContractorAdmin],
  [to.generateContractorInvoices]: [UserRoleKey.Admin],
  [to.addContractorJobsiteAssignments]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  [to.editContractorJobsiteAssignments]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  [to.manageWorkerOnboardingLinks]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.ContractorAdmin,
    UserRoleKey.ContractorForeman,
  ],
  [to.removeContractorJobsiteAssignments]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  [to.seeContractorUsers]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  [to.addContractorUsers]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  [to.editContractorUsers]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  [to.removeContractorUsers]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  [to.removeContractorWorkers]: [UserRoleKey.Admin],

  [to.addDevelopers]: [UserRoleKey.Admin],
  [to.editDevelopers]: [UserRoleKey.Admin],

  [to.addOrganizations]: [UserRoleKey.Admin],
  [to.editOrganizations]: [UserRoleKey.Admin],

  // src/components/cards/JobsiteWorkerDocumentCard.tsx
  [to.editMetadata]: (user: User): boolean =>
    userHasSomeRoleOtherThan(user, [UserRoleKey.Worker, UserRoleKey.JobsiteReader, UserRoleKey.JobsiteDirectoryReader]),

  // src/components/jobsiteWorkerCard/JobsiteWorkerCard.tsx
  [to.changeContractor]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
    UserRoleKey.JobsiteSecuritySupervisor,
  ],
  [to.correctJobsite]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
    UserRoleKey.JobsiteSecuritySupervisor,
  ],
  [to.debugWorkerAccess]: [UserRoleKey.Admin],
  [to.editJobsiteAccess]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteEditor,
    UserRoleKey.JobsiteMedicSupervisor,
    UserRoleKey.JobsiteSecuritySupervisor,
    UserRoleKey.JobsiteSecurity,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
  ],
  [to.enableJobsiteWorkerSelfOnboarding]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteEditor,
    UserRoleKey.JobsiteMedicSupervisor,
    UserRoleKey.JobsiteSecuritySupervisor,
    UserRoleKey.JobsiteSecurity,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
  ],
  [to.seeAllowEscortPrivileges]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSecuritySupervisor,
  ],
  [to.seeExemptFrom30DaysOfInactivity]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
    UserRoleKey.JobsiteSecuritySupervisor,
  ],
  [to.seePayrollID]: [UserRoleKey.Admin],
  [to.seeExemptFromSiteRefresherTraining]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
    UserRoleKey.JobsiteSecuritySupervisor,
  ],

  // src/components/modals/UpdateBreathDocumentModal.tsx
  // src/components/modals/UploadDrugTestDocumentModal.tsx
  // src/containers/jobsiteWorkerOrientation/helpers/useJobsiteWorkerOnboardingDocuments.ts
  [to.accessPhiDocuments]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin, UserRoleKey.JobsiteMedicSupervisor],

  // src/containers/home/HomeContainer.tsx
  [to.redirectToDashboard]: [
    UserRoleKey.Admin,
    UserRoleKey.Super,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteMedicSupervisor,
    UserRoleKey.JobsiteSecurity,
    UserRoleKey.JobsiteReader,
  ],

  // src/containers/jobsites/JobsitesContainer.tsx
  [to.addJobsites]: [UserRoleKey.Admin, UserRoleKey.Super, UserRoleKey.ClientAdmin],
  [to.closeOutJobsites]: [UserRoleKey.Admin, UserRoleKey.Super, UserRoleKey.ClientAdmin],
  [to.archiveJobsites]: [UserRoleKey.Admin, UserRoleKey.Super],
  [to.seeJobsiteTemplates]: [UserRoleKey.Admin, UserRoleKey.Super],
  [to.addJobsiteTemplates]: [UserRoleKey.Admin, UserRoleKey.Super],

  // src/containers/jobsite/JobsiteContainer.tsx
  [to.seeJobsiteUsers]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  [to.addJobsiteUsers]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  [to.removeJobsiteUsers]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  [to.editJobsiteConfiguration]: [UserRoleKey.Admin, UserRoleKey.Super],

  // src/containers/jobsiteAnnouncement/JobsiteAnnouncementContainer.tsx
  [to.deleteJobsiteAnnouncement]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],

  // src/containers/jobsiteAnnouncements/JobsiteAnnouncementsContainer.tsx
  [to.addJobsiteAnnouncements]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,

    // These roles don't exist
    // UserRoleKey.JobsiteSiteSafetySupervisor,
    // UserRoleKey.JobsiteSiteSafetySupervisorFitout,
    // Substituting the "Manager" roles for the "Supervisor" roles
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,

    UserRoleKey.JobsiteSecuritySupervisor,
    UserRoleKey.JobsiteMedicSupervisor,
  ],

  // eslint-disable-next-line max-len
  // src/containers/jobsiteSafetyIncident/print/tabs/workerReport/workerReportMedicalDocuments/WorkerReportMedicalDocuments.tsx
  [to.accessWorkerReportMedicalDocuments]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteMedicSupervisor,
  ],

  // src/containers/jobsiteWorkerOrientation/JobsiteWorkerOnboardingSidebarContainer.tsx
  // src/containers/jobsiteWorkerOrientation/helpers/utils.ts
  [to.seeSiteSpecificOrientationStep]: [
    UserRoleKey.Admin,
    UserRoleKey.Super,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteMedicSupervisor,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
  ],

  // src/containers/jobsiteWorkerOrientation/steps/MedicalTestingStep.tsx
  [to.seeMedicalTestingStepContent]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin, UserRoleKey.JobsiteMedicSupervisor],

  // src/containers/jobsiteWorkerOrientation/steps/PersonalInfoOverviewStep.tsx
  // src/containers/worker/tabs/WorkerOverview.tsx
  [to.addUnions]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin, UserRoleKey.JobsiteMedicSupervisor],

  // src/containers/modals/ChangeJobsiteAssignmentModalContainer.tsx
  [to.chooseToDeleteJobsiteAssignment]: [UserRoleKey.Admin],

  // src/containers/modals/helpers/index.ts
  [to.seeCorrectionOrientationOption]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
  ],

  // src/containers/navbar/NavbarTailwindContainer.tsx
  [to.searchForWorker]: (user: User): boolean =>
    userHasSomeRoleOtherThan(user, [
      UserRoleKey.Worker,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
      UserRoleKey.JobsiteDirectoryReader,
    ]),

  // src/containers/searchWorkers/SearchWorkersContainer.tsx
  [to.runWorkerActionsFromSearch]: (user: User): boolean =>
    userHasSomeRoleOtherThan(user, [UserRoleKey.Worker, UserRoleKey.JobsiteReader, UserRoleKey.JobsiteDirectoryReader]),

  // src/containers/users/helpers/useUsersData.ts
  [to.queryForUserData]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin, UserRoleKey.Super],

  // src/containers/worker/tabs/LookerWorkerActivity.tsx
  [to.manageJobsiteAccessEvents]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  [to.deleteAccessEvents]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
  ],
  [to.archiveAccessEvents]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
  ],

  // src/containers/worker/tabs/WorkerAdminActions.tsx
  [to.accessWorkerAdminSection]: [UserRoleKey.Admin, UserRoleKey.Super],
  [to.seeWorkerCCureData]: [UserRoleKey.Admin],

  // src/containers/worker/tabs/WorkerDocuments.tsx
  [to.addWorkerDocuments]: (user: User): boolean =>
    userHasSomeRoleOtherThan(user, [UserRoleKey.Worker, UserRoleKey.JobsiteReader, UserRoleKey.JobsiteDirectoryReader]),
  [to.uploadDocumentVersion]: (user: User): boolean =>
    userHasSomeRoleOtherThan(user, [UserRoleKey.Worker, UserRoleKey.JobsiteReader, UserRoleKey.JobsiteDirectoryReader]),

  // src/containers/worker/tabs/WorkerMedical.tsx
  [to.accessWorkerMedicalDocuments]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteMedicSupervisor,
    UserRoleKey.JobsiteDirectoryReader,
  ],
  [to.addWorkerMedicalDocuments]: [UserRoleKey.JobsiteMedicSupervisor, UserRoleKey.JobsiteAdmin, UserRoleKey.Admin],

  // src/containers/worker/tabs/WorkerOverview.tsx
  [to.viewWorkerIDs]: [UserRoleKey.Admin, UserRoleKey.JobsiteAdmin],
  [to.editWorkerPersonalInfo]: (user: User): boolean =>
    userHasSomeRoleOtherThan(user, [UserRoleKey.Worker, UserRoleKey.JobsiteReader, UserRoleKey.JobsiteDirectoryReader]),

  // src/containers/worker/WorkerContainer.tsx
  [to.assignWorkers]: (user: User): boolean =>
    userHasSomeRoleOtherThan(user, [
      UserRoleKey.Worker,
      UserRoleKey.JobsiteReader,
      UserRoleKey.JobsiteEditor,
      UserRoleKey.JobsiteDirectoryReader,
    ]),
  [to.seeWorkerJobsiteActions]: (user: User): boolean =>
    userHasSomeRoleOtherThan(user, [UserRoleKey.Worker, UserRoleKey.JobsiteDirectoryReader]),

  [to.seeWorkerProfileOverview]: (user: User): boolean => userHasSomeRoleOtherThan(user, [UserRoleKey.Worker]),
  [to.seeWorkerProfileJobsites]: (user: User): boolean => userHasSomeRoleOtherThan(user, [UserRoleKey.Worker]),
  [to.seeWorkerProfileDocuments]: (user: User): boolean => userHasSomeRoleOtherThan(user, [UserRoleKey.Worker]),
  [to.seeWorkerProfileMedical]: [
    UserRoleKey.Admin,
    UserRoleKey.JobsiteMedicSupervisor,
    UserRoleKey.JobsiteDirectoryReader,
  ],
  [to.seeWorkerProfileBadges]: (user: User): boolean =>
    userHasSomeRoleOtherThan(user, [UserRoleKey.Worker, UserRoleKey.JobsiteReader, UserRoleKey.JobsiteMedicSupervisor]),
  [to.seeWorkerProfileAccessActivity]: (user: User): boolean =>
    userHasSomeRoleOtherThan(user, [UserRoleKey.Worker, UserRoleKey.JobsiteReader, UserRoleKey.JobsiteDirectoryReader]),
  [to.seeWorkerProfileTimecards]: (user: User): boolean =>
    userHasSomeRoleOtherThan(user, [UserRoleKey.Worker, UserRoleKey.JobsiteReader, UserRoleKey.JobsiteDirectoryReader]),
  [to.seeWorkerProfileFormSubmissions]: (user: User): boolean =>
    userHasSomeRoleOtherThan(user, [UserRoleKey.Worker, UserRoleKey.JobsiteReader, UserRoleKey.JobsiteDirectoryReader]),
  [to.seeWorkerProfileComments]: (user: User): boolean =>
    userHasSomeRoleOtherThan(user, [
      UserRoleKey.Worker,
      UserRoleKey.JobsiteReader,
      UserRoleKey.ContractorAdmin,
      UserRoleKey.ContractorForeman,
      UserRoleKey.JobsiteDirectoryReader,
    ]),
  [to.seeWorkerProfileActions]: [UserRoleKey.Admin, UserRoleKey.Super],
  [to.seeWorkerProfileUserRoles]: [UserRoleKey.Admin, UserRoleKey.Super, UserRoleKey.JobsiteAdmin],
  [to.generateWalletWorkerCard]: [UserRoleKey.Admin, UserRoleKey.Super],
  [to.changeAuthenticatedWorkerCredentials]: [UserRoleKey.Admin, UserRoleKey.Super, UserRoleKey.JobsiteAdmin],
  [to.seeChanges]: [UserRoleKey.Admin, UserRoleKey.Super],
  [to.editFormSubmissions]: [
    UserRoleKey.Admin,
    UserRoleKey.Super,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
    UserRoleKey.ContractorAdmin,
    UserRoleKey.ContractorForeman,
  ],
  [to.removeFormSubmissions]: [
    UserRoleKey.Admin,
    UserRoleKey.Super,
    UserRoleKey.JobsiteAdmin,
    UserRoleKey.JobsiteSiteSafetyManager,
    UserRoleKey.JobsiteSiteSafetyManagerFitout,
    UserRoleKey.ContractorAdmin,
    UserRoleKey.ContractorForeman,
  ],
};
