import React from 'react';
import cn from 'classnames';
import moment from 'moment-timezone';
import { Avatar, getFaIcon, Tooltip, TooltipPanel } from '@odin-labs/components';
import { getFullNameForUser } from 'utils';
import { ClockIcon, faUserHardHat, MobileIcon, ShieldCheckIcon } from 'components/icons';
import { Worker } from 'containers/worker/types';
import { getPrettyFormattedDateTimeWithTz } from 'utils/dates';

export type WorkerTitleProps = {
  worker: Worker;
};
const UserHardHatIcon = getFaIcon({ icon: faUserHardHat, className: cn('odin-text-odin-primary') });

export const classes = {
  titleSkeleton: cn(
    'odin-text-transparent odin-text-2xl',
    'odin-animate-pulse odin-bg-gray-300 odin-rounded-md -odin-ml-1',
  ),
};

export function WorkerTitle({ worker }: WorkerTitleProps): React.ReactElement {
  const workerUser = worker?.user;
  const name = getFullNameForUser(workerUser);
  const { createdAt: lastSessionCreatedAt } = workerUser?.lastSession ?? {};
  const timeZone = moment.tz.guess();
  const lastActiveAt = lastSessionCreatedAt && getPrettyFormattedDateTimeWithTz(lastSessionCreatedAt, timeZone);

  return (
    <div className="odin-flex odin-gap-x-8">
      <Avatar icon={UserHardHatIcon} type="rounded" objectFit="cover" size="2xl" />
      <div className="odin-flex odin-flex-col odin-justify-around">
        <h1 className="odin-text-xl odin-font-bold odin-text-odin-black sm:odin-text-2xl sm:odin-truncate">
          {name ? (
            <>
              <span>{name}</span>
              {worker?.user?.isMobileUser && (
                <Tooltip placement="right">
                  <span
                    className={`odin-inline-flex odin-items-center odin-ml-4 odin-p-2 odin-rounded-md 
              odin-font-normal odin-text-base odin-text-gray-800 odin-bg-gray-200`}
                  >
                    <MobileIcon />
                  </span>
                  <TooltipPanel>Worker app installed</TooltipPanel>
                </Tooltip>
              )}
            </>
          ) : (
            <span className={classes.titleSkeleton}>Loading Contractor Data</span>
          )}{' '}
        </h1>
        <div className="odin-flex odin-flex-wrap odin-gap-y-2 odin-gap-x-4 odin-mt-2 sm:odin-mt-0 odin-items-center ">
          <span className="odin-text-sm odin-flex odin-items-center">
            <ShieldCheckIcon className="odin-text-base odin-text-odin-primary odin-mr-2" />
            {worker?.quickCode}
          </span>
          {lastActiveAt && (
            <span className="odin-text-sm odin-flex odin-items-center">
              <ClockIcon className="odin-text-base odin-text-odin-primary odin-mr-2" />
              Last Active {lastActiveAt}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
